import HeaderOne from "../components/HeaderOne";
import FooterOne from "../components/FooterOne";
import { useEffectOnce } from "../utils/func";
import { useState } from "react";
import Preloader from "../helper/Preloader";
import * as baseApi from '../api/base'

const BaseLayout = ({ children,onBaseInfo }) => {

    const [base,setBase] = useState({
        company_address: "",
        contact_number: "",
        description: "",
        domain: "",
        email: "",
        favicon:"",
        formspree_id: "",
        keywords:"",
        logo: "",
        map_url:"",
        site_name:""
    })

    useEffectOnce(() => {
        console.log('baselyaout useeffectonce called...')
        loadBaseInfo()
    })

    async function loadBaseInfo(){
       const response = await baseApi.info();
        if(response && response.code == 0) {
            const info = response.result.info
            setBase(info)
            if( onBaseInfo && typeof onBaseInfo === 'function')
            {
                onBaseInfo(info)
            }
            
        }
    }

    return <div>

        {/* Preloader */}
        <Preloader />
        
         {/* HeaderOne */}
        <HeaderOne 
            logo={base.logo}
        />

        {children}
           
        {/* FooterOne */}
        <FooterOne 
            logo={base.logo}
            description={base.description}
            email={base.email}
            address={base.company_address}
            site_name={base.site_name}
            contact_number={base.contact_number}
        />
    </div>
}

export default BaseLayout