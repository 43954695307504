import { GOOD_TYPE_NORMAL, GOOD_TYPE_SECKILL } from "../constants";


export const getGoodDetailUrl = (sn, seckillId) => {

    let url = "/product-details"
    if(sn) {
        url = `${url}/${sn}?type=${GOOD_TYPE_NORMAL}`
    } else {
        url = `${url}/${seckillId}?type=${GOOD_TYPE_SECKILL}`
    }
    
    return url;
}

