import { Link } from "react-router-dom"
import { getGoodDetailUrl } from "../../utils"

const ProductSeckillCard = ({ seckill }) => {
    const linkurl = getGoodDetailUrl(undefined, seckill.seckill_id)
    return (
        <div className="col-sm-6">
            <div className="product-item box-shadow">
                <div className="product-item__thumb d-flex">
                <Link to={linkurl} className="link w-100">
                    <img
                    src={seckill.seckill_img}
                    alt=""
                    className="cover-img"
                    />
                </Link>
                <button type="button" className="product-item__wishlist">
                    <i className="fas fa-heart" />
                </button>
                </div>
                <div className="product-item__content">
                <h6 className="product-item__title">
                    <Link to={linkurl} className="link">
                    {seckill.seckill_title}
                    </Link>
                </h6>
                <div className="product-item__info flx-between gap-2">
                    <span className="product-item__author">
                    by
                    <Link
                        to="profile"
                        className="link hover-text-decoration-underline"
                    >
                        -
                    </Link>
                    </span>
                    <div className="flx-align gap-2">
                    <h6 className="product-item__price mb-0">${seckill.seckill_price}</h6>
                    <span className="product-item__prevPrice text-decoration-line-through">
                        ${seckill.line_price > 0 ? seckill.line_price : seckill?.price}
                    </span>
                    </div>
                </div>
                <div className="product-item__bottom flx-between gap-2">
                    <div>
                    <span className="product-item__sales font-14 mb-2">
                        {seckill.sale_num} Sales
                    </span>
                    <div className="d-flex align-items-center gap-1">
                        <ul className="star-rating">
                        <li className="star-rating__item font-11">
                            <i className="fas fa-star" />
                        </li>
                        <li className="star-rating__item font-11">
                            <i className="fas fa-star" />
                        </li>
                        <li className="star-rating__item font-11">
                            <i className="fas fa-star" />
                        </li>
                        <li className="star-rating__item font-11">
                            <i className="fas fa-star" />
                        </li>
                        <li className="star-rating__item font-11">
                            <i className="fas fa-star" />
                        </li>
                        </ul>
                        <span className="star-rating__text text-heading fw-500 font-14">
                        {`(${seckill?.rating})`}
                        </span>
                    </div>
                    </div>
                    <Link
                        to={linkurl}
                        className="btn btn-outline-light btn-sm pill"
                    >
                        Live Demo
                    </Link>
                </div>
                </div>
            </div>
        </div>
    )
}

export default ProductSeckillCard