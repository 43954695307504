
import {
    post
} from '../utils/request'

// Axios Get Call - Get all jokes categories.
export const createOrderByGoodsSkuId = async (params = {}) => {
    try {
        const response = await post("/buy/createOrderByGoodsSkuId",params);
        return response.data;
    }
    catch(error){
        return error.response;
    }
}

export const createOrderByPaypal = async (params = {}) => {
    try {
        const response = await post("/buy/createOrderByPaypal",params);
        return response.data;
    }
    catch(error){
        return error.response;
    }
}

export const captureOrderByPaypal = async (params = {}) => {
    try {
        const response = await post("/buy/captureOrderByPaypal",params);
        return response.data;
    }
    catch(error){
        return error.response;
    }
}

export const pay = async (params = {}) => {
    try {
        const response = await post("/buy/pay",params);
        return response.data;
    }
    catch(error){
        return error.response;
    }
}