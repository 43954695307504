
import {
    get
} from '../utils/request'

// Axios Get Call - Get all jokes categories.
export const info = async () => {
    try {
        const response = await get("/base/info");
        return response.data;
    }
    catch(error){
        return error.response;
    }
}