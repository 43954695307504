import React, { useState } from "react";
import BannerOne from "../components/BannerOne";
import PopularOne from "../components/PopularOne";
import ArrivalOne from "../components/ArrivalOne";
import FlashDealOne from "../components/FlashDealOne";
import SellingOne from "../components/SellingOne";
import FeaturedAuthor from "../components/FeaturedAuthor";
import PerformanceAuthor from "../components/PerformanceAuthor";
import BlogOne from "../components/BlogOne";
import BecomeSellerOne from "../components/BecomeSellerOne";
import BrandSectionOne from "../components/BrandSectionOne";

import BaseLayout from "../layout/BaseLayout";
import { useEffectOnce } from "../utils/func";
import * as goodsCategoryApi from '../api/goodsCategory'
import * as goodsApi from '../api/goods'
import * as seckillApi from '../api/seckill'
import { SORT_TYPE } from "../constants";

const HomePage = () => {

  const [baseInfo,setBaseInfo] = useState(null)
  const [goodsCategoryList,setGoodsCategoryList] = useState([])
  const [goodsList,setGoodsList] = useState([])
  const [seckillList,setSeckillList] = useState([])
  const [bestSellList,setBestSellList] = useState([])
  const [topAuthor,setTopAuthor] = useState({
    user_id: 0,
    profile_img: "",
  })
  const [topAuthGoodsList,setTopAuthorGoodsList] = useState([])

  useEffectOnce(() => {
    load()
  })

  function load(){
    loadCategoryList()
    loadGoodsList()
    loadSeckillList()
    loadBestSellList()
 
  }

  async function loadCategoryList() {
    const response = await goodsCategoryApi.list()
    if(response && response.code == 0) {
      setGoodsCategoryList(response.result.list)
    }
  }

  async function loadGoodsList(params = {}) {
    
    const payload = Object.assign({ rows: 8, ...params })
    const response = await goodsApi.list( payload )
    if(response && response.code === 0) {
      setGoodsList(response.result.list)
    }
  }

  async function loadSeckillList( params = { }) {
    const response = await seckillApi.list( params )
    if(response && response.code === 0) {
      setSeckillList(response.result.list)
    }
  }

  async function loadBestSellList(){
    const payload = Object.assign({ rows:4, sort_type: SORT_TYPE.sale_high_to_low })
    const response = await goodsApi.list( payload )
    if(response && response.code === 0) {
      setBestSellList(response.result.list)

      loadTopAuthor(response.result.list[0])
    }
  }

  async function loadTopAuthor(bestSellGood)
  {
    // const topAuthor = bestSellList[0];
    console.log('best sell good is ', bestSellGood)
    const supplier = bestSellGood?.supplier
    setTopAuthor(supplier)

    const payload = {
      rows: 4, 
      supplier_id: supplier?.id 
    }
    console.log('payload is', payload )
    const response = await goodsApi.list(payload)
    console.log('supplier good is ', response)
    if(response && response.code === 0) {
      setTopAuthorGoodsList(response.result.list)
    }
  }

  return (
    <section className="change-gradient">
 

    {/* SaleOffer */}
    {/* <SaleOffer /> */}


    <BaseLayout
      onBaseInfo={info => {
        setBaseInfo(info)
      }}
    >
        {/* BannerOne */}
        <BannerOne />

        {/* PopularOne */}
        <PopularOne 
          list={goodsCategoryList}
        />

        {/* ArrivalOne */}
        <ArrivalOne 
          categoryList={goodsCategoryList}
          goodsList={goodsList}
          onCategoryChange={categoryId => loadGoodsList(categoryId > 0 ?  { category_ids: [categoryId]} : {} )}
        />

        {/* FlashDeal */}
        <FlashDealOne 
          list={seckillList}
        />

        {/* SellingOne */}
        <SellingOne 
          list={bestSellList}
        />

        {/* FeaturedAuthor */}
        <FeaturedAuthor 
           author={topAuthor}
           list={topAuthGoodsList}
        />

        {/* PerformanceAuthor */}
        <PerformanceAuthor />

        {/* BlogOne */}
        {/* <BlogOne /> */}

        {/* BecomeSellerOne */}
        <BecomeSellerOne 
          email={baseInfo?.email}
        />

        {/* BrandSectionOne */}
        <BrandSectionOne />

    </BaseLayout>


  </section>
  );
};

export default HomePage;
