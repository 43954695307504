

// export const gra

export const gradient = {
    banner_gradient: require('../assets/images/gradients/banner-gradient.png')
}

export const shapes = {
    element_moon1: require('../assets/images/shapes/element-moon1.png'),
    element_moon2: require('../assets/images/shapes/element-moon2.png'),
    element_moon3: require('../assets/images/shapes/element-moon3.png'),
    element_moon4: require('../assets/images/shapes/element-moon4.png'),

    element2:require('../assets/images/shapes/element2.png'),

    dots: require('../assets/images/shapes/dots.png'),
    dots_white: require('../assets/images/shapes/dots-white.png'),
}

export const icons ={
    cart: require('../assets/images/icons/cart.svg'),
    search: '../assets/images/icons/search.svg',
}

export const thumbs = {
    tech_icon1: require('../assets/images/thumbs/tech-icon1.png'),
    tech_icon_white1: require('../assets/images/thumbs/tech-icon-white1.png'),
    tech_icon2: require('../assets/images/thumbs/tech-icon2.png'),
    tech_icon3: require('../assets/images/thumbs/tech-icon3.png'),
    tech_icon_white3: require('../assets/images/thumbs/tech-icon-white3.png'),
    tech_icon4: require('../assets/images/thumbs/tech-icon4.png'),
    tech_icon5: require('../assets/images/thumbs/tech-icon5.png'),
    tech_icon6: require('../assets/images/thumbs/tech-icon6.png'),
    tech_icon7: require('../assets/images/thumbs/tech-icon7.png'),
    tech_icon8: require('../assets/images/thumbs/tech-icon8.png'),
    tech_icon9: require('../assets/images/thumbs/tech-icon9.png'),

    banner_img: require('../assets/images/thumbs/banner-img.png')
}