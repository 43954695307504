
import dayjs from "dayjs"
var relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(relativeTime);


export const timeAgo = (time) => {
    return  dayjs().to(dayjs(time * 1000))
}

export const locale = (time,format="YYYY-MM-DD") => {
    return dayjs(time * 1000).format(format)
}