
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import CreditCard from "./payment/creditcard";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import * as buyApi from '../api/buy'
import * as orderPayApi from '../api/orderPay'
import Loading from '../components/Loading'
import { useEffectOnce } from "../utils/func";
import {QRCodeSVG} from 'qrcode.react';
const Payment = ({
    paynameList,
    amount,
    pay_sn,
    sn,
    paypal_client_id,
    onNavigate,
}) => {

    const [loading,setLoading] = useState(false)
    const [method,setMethod] = useState(paynameList[0])
    const fakeMethods = [1,2,3,4,5,6,7,8,9,10]

    const [content,setContent] = useState(null)

    async function handlePaymentMethod(method) {
        console.log('handle payment method ,,',method)
        setMethod(method)

        if( method?.name === "wechat") {
            const content = await loadPayParam(method)
            console.log('loadPayParam is', content)
            setContent(content)
            startCronQuery()
        } else if(method?.name === "alipay") {
            const content = await loadPayParam(method)
            console.log('loadPayParam is', content)
            setContent(content)

            // let divForm = document.getElementsByTagName('divform')
            // if (divForm.length) {
            //     document.body.removeChild(divForm[0])
            // }
            // const div=document.createElement('divform');
            // div.innerHTML= content ;
            // document.body.appendChild(div);
            // document.forms[0].setAttribute('target', '_blank') 
            // document.forms[0].submit();
        }
    }

    function SampleNextArrow(props) {
        const { className, onClick } = props;
        return (
            <button className={className} onClick={onClick}>
                <i className="las la-arrow-right" />
            </button>
        );
    }
    function SamplePrevArrow(props) {
        const { className, onClick } = props;

        return (
            <button className={className} onClick={onClick}>
                <i className="las la-arrow-left" />
            </button>
        );
    }
    const settings = {
        dots: false,
        arrows: true,
        infinite: false,
        speed: 1000,
        slidesToShow: 10,
        slidesToScroll: 10,
        initialSlide: 0,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 8,
                    slidesToScroll: 8,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 6,
                    slidesToScroll: 6,
                    initialSlide: 2,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 5,
                },
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                },
            },
        ],
    };

    async function loadPayParam(currentMethod) {
        setLoading(true)
        console.log('loadPayParam', currentMethod, paynameList)
        try {
            if(currentMethod?.name == "wechat" || currentMethod?.name == "alipay") {
                const param = {
                    order_type: "goods_buy",
                    pay_sn,
                    payment_code: currentMethod.name,
                    payment_channel:currentMethod.name
                }
                const response = await buyApi.pay(param);
                console.log('103 支付结果',response)
                if(response && response.code == 0) {
                //    setContent(content)
                    // afterPay(response.result.content)
                    return response.result.content;
                } else {
                    return ""
                }
            }
        } catch (error) {
            
        } finally {
            setLoading(false)
        }
    }


    /**
   * 微信开启定时查询，查询订单已经支付，就跳转
   * 2s查询一次
  */
  function startCronQuery()
  {
    console.log('start cron query ', pay_sn)
    let timeCount = 0;
    let timer = setInterval(async () => {
      timeCount += 2;

      if(method?.name !== "wechat") {
        clearInterval(timer)
      }
      // query order pay state
      const response = await orderPayApi.info({ pay_sn })

      if(response && response.code === 0) {
        const orderPay = response.result.info;
        if(orderPay.pay_state === 1) {
            clearInterval(timer)
            if(method?.name === "wechat") {
                onNavigate(orderPay.sn)
            }
            
        } else {
            console.log('orderPAY paystate not 1')
        }
      } 
      if(timeCount == 60) {
        console.log('时间到或者已经支付成功了，跳转到订单详情页面')
        clearInterval(timer)

        onNavigate(sn)
      }
      
    },3000);
  }

    function renderPay()
    {
        if(loading) {
            return <Loading />
        }

        switch(method?.name) {
            case 'wechat':
                return <div>
                    {
                        content ? (<>
                             <QRCodeSVG value={content} />
                             <div>Scan the code to pay ${amount}</div>
                        </>  ) : <div>Select Payment Method</div> 
                    }
                   
                </div>
            case  'alipay':
                    return (
                        <button
                            className="btn btn-main flx-align gap-2 pill"
                            onClick={() => {
                                loadPayParam(method)
                            }}
                        >
                            To Pay
                            <span className="icon line-height-1 font-20">
                            <i className="las la-arrow-right" />
                            </span>
                        </button>
                    )
            case 'creditcard':
                return <CreditCard /> 
            case 'paypal':
            default:
                return renderPaypal()
        }
    }

    function renderPaypal() {
        const initialOptions = {
            "client-id": paypal_client_id
            // "client-id": paypal_client_id,
            // "enable-funding": "paylater,venmo",
            // "data-sdk-integration-source": "integrationbuilder_sc",
          };
        return (
            <PayPalScriptProvider options={initialOptions}>
                <PayPalButtons 
                    style={{ 
                        layout: "vertical",
                        shape: "rect",
                        //color:'blue' change the default color of the buttons
                        layout: "vertical", //default value. Can be changed to horizontal
                     }} 
                    createOrder={async (data) => {
                        console.log('createOrder 108',data)
                        try {
                            const response = await buyApi.createOrderByPaypal({ pay_sn })
                            console.log('1111 create payapl repsonse',response)
                            return response.result;
                        } catch (error) {
                            throw new Error(error); 
                        }
                      
                    }}
                    onApprove={async (data,actions) =>  {
                        console.log('on approve 117',data)
                        try {
                            const payload = {
                                paypal_order_id: data.orderID
                            }
                            const response = await buyApi.captureOrderByPaypal(payload)
                            console.log('captureorder response is', response)
                            if(response && response.code === 0) {
                                console.log('这里要跳转到订单详情页面',sn)
                                onNavigate(sn)
                            }
                        } catch (error) {
                            throw new Error(error)
                        }
                    }}
                    onCancel={() => {
                        console.log('on paypal cancel')
                        onNavigate(sn)
                    }}
                    />
            </PayPalScriptProvider>)
    }

    return (
        <section className="cart-payment padding-y-120 overflow-hidden">
            <div className="container container-two">
                <div className="payment-method">
                    <h5 className="payment-method__title mb-4">Select One Payment Method</h5>
                    <div className="payment-method__slider arrow-sm">
                        <Slider {...settings}>
                            
                            {
                                paynameList && paynameList?.length > 0 && paynameList.map((item,index) => {
                                    return (
                                        <div 
                                            className="payment-method__item" 
                                            key={`pay-${index}`}
                                            onClick={() => { handlePaymentMethod(item) }}
                                            >
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name={item?.name}
                                                id={item?.name}
                                                hidden
                                            />
                                            <label className="form-check-label" htmlFor="payment1">
                                                <img src={item?.logo} alt="" />
                                            </label>
                                        </div>
                                    )
                                })
                            }
                            

                            {
                                fakeMethods.map((item,index) => {
                                    return (
                                        <div className="payment-method__item" key={`fake-${index}`}>
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="payment"
                                                id="payment2"
                                                hidden
                                            />
                                            <label className="form-check-label" htmlFor="payment2">
                                                <img src="../assets/images/thumbs/payment-method2.png" alt="" />
                                            </label>
                                        </div>
                                    ) 
                                })
                            }
                            
                            
                        </Slider>
                    </div>
                </div>
                <div className="cart-payment__box position-relative z-index-1 overflow-hidden">
                    <img
                        src={require('../assets/images/shapes/pattern-curve-six.png')}
                        alt=""
                        className="position-absolute end-0 top-0 z-index--1"
                    />
                    <img
                        src={require('../assets/images/shapes/pattern-curve-five.png')}
                        alt=""
                        className="position-absolute start-0 top-0 z-index--1"
                    />
                    <div className="row justify-content-center">
                        <div className="col-lg-8 col-sm-10">
                            {/* 这个是为了首次加载 */}
                            { renderPaypal()}
                            {renderPay()}
                        </div>
                    </div>
                </div>
                <div className="cart-content__bottom flx-between gap-2">
                    <Link
                        to="/cart-personal"
                        className="btn btn-outline-light flx-align gap-2 pill btn-lg"
                    >
                        <span className="icon line-height-1 font-20">
                            <i className="las la-arrow-left" />
                        </span>
                        Back
                    </Link>
                </div>
            </div>
        </section>

    );
}

export default Payment;