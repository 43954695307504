import { Link } from "react-router-dom";
import ProductSeckillCard from "./product/seckillCard";
import { GOOD_TYPE_SECKILL } from "../constants";

const FlashDealOne = ({
  list
}) => {
  return (
    <section className="featured-product padding-y-120 position-relative z-index-1">
      <img
        src="../assets/images/gradients/featured-gradient.png"
        alt=""
        className="bg--gradient white-version"
      />
      <img
        src="../assets/images/shapes/spider-net.png"
        alt=""
        className="spider-net position-absolute top-0 end-0 z-index--1 white-version"
      />
      <img
        src="../assets/images/shapes/spider-net-white.png"
        alt=""
        className="spider-net position-absolute top-0 end-0 z-index--1 dark-version"
      />
      <img
        src="../assets/images/shapes/element1.png"
        alt=""
        className="element two"
      />
      <div className="container container-two">
        <div className="row gy-4 flex-wrap-reverse align-items-center">
          <div className="col-xl-6">
            <div className="row gy-4 card-wrapper">

              {
                list && list.length > 0 && list.map(item => {
                  return (
                    <ProductSeckillCard 
                      key={`seckill-${item.id}`}
                      seckill={item}
                    />
                  )
                })
              }
              
            </div>
          </div>
          <div className="col-xl-1 d-xl-block d-none" />
          <div className="col-xl-5">
            <div className="section-content">
              <div className="section-heading style-left">
                <h3 className="section-heading__title">Flash Deal Products</h3>
                <p className="section-heading__desc font-18 w-sm">
                  Every month we pick some flash deal products for you. This month's
                  best web themes &amp; templates have arrived, chosen by our
                  content specialists.
                </p>
              </div>
              <Link
                to={`/all-product?type=${GOOD_TYPE_SECKILL}`}
                className="btn btn-main btn-lg pill fw-300"
              >
                View All Items
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FlashDealOne;
