import CommentCard from "./card"

const CommentsList = ({ list }) => {
    return (
        <div>
            {
                list.map((item,index) => {
                    return <ul className="comment-list" key={`comment-${index}`}>
                        <CommentCard comment={item} />
                        {
                            item?.child && item?.child?.length > 0 && item?.child.map((citem,cindex) => {
                                return (
                                    <li key={`cli-${index}`}>
                                        <ul className="comment-list comment-list--two">
                                            <CommentCard comment={citem}/>
                                        </ul>
                                    </li>
                                )
                            })
                        }
                        
                    </ul>
                })
            }
        </div>
        // <ul className="comment-list">
        //     <CommentCard />
        //     <li>
        //         <ul className="comment-list comment-list--two">
        //             <CommentCard />
        //         </ul>
        //     </li>
        // </ul>
    )
}

export default CommentsList