import React, { useState } from "react";
import BrandSectionOne from "../components/BrandSectionOne";
import BreadcrumbEight from "../components/BreadcrumbEight";
import Contact from "../components/Contact";
import NewsletterTwo from "../components/NewsletterTwo";
import BaseLayout from "../layout/BaseLayout";
const ContactPage = () => {

  const [info,setInfo] = useState(null)
  return (
    <BaseLayout
      onBaseInfo={info => {
        console.log('13 info is', info)
        setInfo(info)
      }}
    >

        {/* BreadcrumbEight */}
        <BreadcrumbEight />

        {/* Contact */}
        <Contact 
          email={info?.email}
          contact_number={info?.contact_number}
          description={info?.description}
          formspree_id={info?.formspree_id}
        />

        {/* NewsletterTwo */}
        <NewsletterTwo />

        {/* BrandSectionOne */}
        <BrandSectionOne />
    </BaseLayout>
  );
};

export default ContactPage;
