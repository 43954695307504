
import { Link } from "react-router-dom"
const Stepper = ({ data }) => {

    return (
        <ul className="process-list">
            {
                data && data.length > 0 && data.map((item,index) => {

                    // const className = item?.enable ? "process-list__item activePage" : "process-list__item"
                    const className = "process-list__item activePage"
                    return (
                        <li className={className} key={`li-${index}`}>
                            <Link to={item?.url} className="process-list__link">
                                <div className="icons">
                                    <span className="icon white">
                                        <img src={item?.icon} alt="" />
                                    </span>
                                </div>
                                <span className="text">{item?.title}</span>
                            </Link>
                        </li>
                    )
                })
            }
                
                {/* <li className="process-list__item activePage">
                  <Link to="/cart-personal" className="process-list__link">
                    <div className="icons">
                      <span className="icon white">
                        <img src="assets/images/icons/process-white2.svg" alt="" />
                      </span>
                      <span className="icon colored">
                        <img src="assets/images/icons/process2.svg" alt="" />
                      </span>
                    </div>
                    <span className="text">Mailing Address</span>
                  </Link>
                </li>
                <li className="process-list__item activePage">
                  <Link to="/cart-payment" className="process-list__link">
                    <div className="icons">
                      <span className="icon white">
                        <img src="../assets/images/icons/process-white3.svg" alt="" />
                      </span>
                      <span className="icon colored">
                        <img src="assets/images/icons/process3.svg" alt="" />
                      </span>
                    </div>
                    <span className="text">Payment Methods</span>
                  </Link>
                </li>
                <li className="process-list__item">
                  <Link to="/cart-thank-you" className="process-list__link">
                    <div className="icons">
                      <span className="icon white">
                        <img src="assets/images/icons/process-white4.svg" alt="" />
                      </span>
                      <span className="icon colored">
                        <img src="assets/images/icons/process4.svg" alt="" />
                      </span>
                    </div>
                    <span className="text">Preview Order</span>
                  </Link>
                </li> */}
              </ul>
    )
}

export default Stepper