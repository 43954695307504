
import { Link } from "react-router-dom"
import { timeAgo } from "../../utils/time"
const ProductReviewCard = ({ review } ) => {

    return (
        <div className="product-review">
            <div className="product-review__top flx-between">
                <div className="product-review__rating flx-align">
                    <div className="d-flex align-items-center gap-1">
                        <ul className="star-rating">
                            <li className="star-rating__item font-11">
                                <i className="fas fa-star" />
                            </li>
                            <li className="star-rating__item font-11">
                                <i className="fas fa-star" />
                            </li>
                            <li className="star-rating__item font-11">
                                <i className="fas fa-star" />
                            </li>
                            <li className="star-rating__item font-11">
                                <i className="fas fa-star" />
                            </li>
                            <li className="star-rating__item font-11">
                                <i className="fas fa-star" />
                            </li>
                        </ul>
                        <span className="star-rating__text text-body">{review.rate}</span>
                    </div>
                    <span className="product-review__reason">
                        For{" "}
                        <span className="product-review__subject">
                            {review.title}
                        </span>{" "}
                    </span>
                </div>
                <div className="product-review__date">
                    by{" "}
                    <Link to="#" className="product-review__user text--base">
                        {review?.nickname}{" "}
                    </Link>{" "}
                    {/* 2 month ago */}
                    {/* {dayjs(review?.create_time * 1000).fro} */}
                    {/* {dayjs().to(dayjs(review?.create_time * 1000))} */}
                    {timeAgo(review?.create_time)}
                </div>
            </div>
            <div className="product-review__body">
                <p className="product-review__desc">
                    {review.message}
                </p>
            </div>
        </div>
    )
}

export default ProductReviewCard