// import RotatingLines 
import { RotatingLines } from "react-loader-spinner";
import { COLORS } from "../constants";

const Loading = ({ loading=true, color=`${COLORS.primary}`,size=150 }) => {

    return (
        <RotatingLines
            visible={loading}
            height="96"
            width="96"
            color={color}
            strokeColor={color}
            strokeWidth="5"
            animationDuration="0.75"
            ariaLabel="rotating-lines-loading"
            wrapperStyle={{}}
            wrapperClass=""
            />
    ) 
    // return <div>loading</div>
}

export default Loading;