import { useState } from "react";
import { Link } from "react-router-dom";
import ProductVerticalCard from "./product/verticalCard";
import { GOOD_TYPE_NORMAL } from "../constants";


const ArrivalOne = ({
  categoryList,
  goodsList,
  onCategoryChange
}) => {

  const [selectCategoryId,setSelectCategoryId] = useState(categoryList[0]?.id)

  function handleCategoryChange(categoryId) {
    onCategoryChange(categoryId)
  }

  function renderCategoryList() {

    const clist = [{ id: 0, name: "all-items"}, ...categoryList]

    return (
      <ul
          className="nav common-tab justify-content-center nav-pills mb-48"
          id="pills-tab"
          role="tablist"
        >
          {
            clist?.length > 0 && clist.map((item, index) => {
              return (
                <li className="nav-item" role="presentation" key={`li-${index}`}>
                  <button
                    className="nav-link"
                    id="pills-wordPress-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-wordPress"
                    type="button"
                    role="tab"
                    aria-controls="pills-wordPress"
                    aria-selected="false"
                    onClick={() => handleCategoryChange(item.id)}
                  >
                    {item.name}
                </button>
              </li>
              )
            })
          }
        </ul>
    )
  }

  return (
    <section className="arrival-product padding-y-120 section-bg position-relative z-index-1">
      <img
        src="../assets/images/gradients/product-gradient.png"
        alt=""
        className="bg--gradient white-version"
      />
      <img
        src="../assets/images/shapes/element2.png"
        alt=""
        className="element one"
      />
      <div className="container container-two">
        <div className="section-heading">
          <h3 className="section-heading__title">New Arrival Products</h3>
        </div>
        {
          renderCategoryList()
        }
        <div className="tab-content" id="pills-tabContent">
          <div
            className="tab-pane fade show active"
            id="pills-all"
            role="tabpanel"
            aria-labelledby="pills-all-tab"
            tabIndex={0}
          >
            <div className="row gy-4">
             
             { 
               goodsList && goodsList.length > 0 && goodsList.map(good => {
                return <ProductVerticalCard
                  key={`good-${good.id}`}
                  good={good}
                />
               })
             }
            </div>
            </div>  
        </div>
        <div className="text-center mt-64">
          <Link
            to={`/all-product?type=${GOOD_TYPE_NORMAL}`}
            className="btn btn-main btn-lg pill fw-300"
          >
            View All Products
          </Link>
        </div>
      </div>
    </section>
  );
};

export default ArrivalOne;
