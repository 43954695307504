import axios from "axios";
import { dev_base_url, publish_base_url } from "../constants/config_url";
import { TOKEN } from "../constants";

export const getInstance = (param = {}) => {
    const token = localStorage.getItem(TOKEN)
    const axiosInstance = axios.create({
        baseURL: process.env.NODE_ENV == "development" ? dev_base_url : publish_base_url,
        withCredentials: false,
        headers: {
          'Accept': '*',
          "access-token": token ? token : ""
        }
      })
    
      return axiosInstance;
    }

export const get = (url, params = {}) => {
    
    return getInstance().get(url, { params });
}
// API Axios Post Call.
export const post = (url, data) => {
    return getInstance().post(url, data);
}
// API Axios Put Call.
export const put = (url, data) => {
    return getInstance().put(url, data);
}
// API Axios Delete Call.
export const del = (url) => {
    return getInstance().delete(url);
}