import React, { useState } from "react";
import BrandSectionOne from "../components/BrandSectionOne";
import Payment from "../components/Payment";
import BreadcrumbPayment from "../components/breadcrumb/Payment";
import BaseLayout from "../layout/BaseLayout";
import * as paynameApi from '../api/payname'
import { useEffectOnce } from "../utils/func";
import { useParams } from "react-router-dom";
import * as orderApi from '../api/order'
import { useNavigate } from "react-router-dom";
const PaymentPage = () => {

  let params = useParams();

  const pay_sn = params.pay_sn;

  const navigate = useNavigate()

  const [orderInfo,setOrderInfo] = useState(null)
  console.log('15 pay_sn is', pay_sn)
  useEffectOnce(() => {
    loadInfo()
  })

  async function loadInfo()
  {
    const response = await orderApi.info({ pay_sn })
    console.log('25 here order info is',response)
    if(response && response.code === 0) {
      setOrderInfo(response.result.info)
    }
  }

  const stepData = [
    {
      id: 1,
      url: "#",
      icon: "../assets/images/icons/process-white1.svg",
      title:"Shopping Product",
      enable: true,
    },
    {
      id: 1,
      url: "#",
      icon: "../assets/images/icons/process3.svg",
      title:"Payment Methods",
      enable: true,
    },
    {
      id: 2,
      url: "#",
      icon: "../assets/images/icons/process4.svg",
      title:"Preview Order",
      enable: false 
    },
  ];

  const [paynameList,setPaynameList] = useState([])

  useEffectOnce(() => {
    loadPaynames()
  })

  async function loadPaynames() {
    const response = await paynameApi.list()
    console.log('35 payname response is', response)
    if(response && response.code === 0 ) {
      setPaynameList(response.result.list)
    }
  }

  return (

    <BaseLayout>

      {/* BreadcrumbSix */}
      <BreadcrumbPayment 
        title={"Payment"}
        stepData={stepData}
      />

      {/* CartPayment */}
      {
        paynameList && paynameList.length > 0 && (
          <Payment 
            paynameList={paynameList}
            amount={orderInfo?.amount}
            sn={orderInfo?.sn}
            pay_sn={pay_sn}
            paypay_client_id={orderInfo?.paypay_client_id}
            onNavigate={(sn) => { 
              console.log('92 onNavigate sn',sn, orderInfo)
              navigate(`/order/${sn}`) 
            }}
        />
        )
      }
      

      {/* BrandSectionOne */}
      <BrandSectionOne />
    </BaseLayout>
  );
};

export default PaymentPage;
