import React, { useEffect, useState } from "react";
import BreadcrumbTwo from "../components/BreadcrumbTwo";
import ProductDetails from "../components/ProductDetails";
import BrandSectionOne from "../components/BrandSectionOne";
import BaseLayout from "../layout/BaseLayout";
import * as goodsApi from '../api/goods'
import * as goodsReviewApi from '../api/goodsReview'
import * as seckillApi from '../api/seckill'
import * as seckillBuyApi from '../api/seckillBuy'
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useEffectOnce } from "../utils/func";

import { GOOD_TYPE_NORMAL, GOOD_TYPE_SECKILL, REDDIRECT_URL, TOKEN } from "../constants";
import * as buyApi from '../api/buy'

const ProductDetailsPage = () => {

    const navigate = useNavigate()
    let params = useParams();
    const id = params.id;

    const [queryParameters] = useSearchParams()
    const [type, setType] = useState(GOOD_TYPE_NORMAL)
    const [title,setTitle] = useState("")
    const [description,setDescription] = useState("")
    const [categoryLabel,setCategoryLabel] = useState("")
    const [img,setImg] = useState("")
    const [images,setImages] = useState([])
    const [previewUrl,setPreviewUrl] = useState("")
    const [features,setFeatures] = useState([])
    const [tags,setTags] = useState([])
    const [skus,setSkus] = useState([])
    const [seckillGoods,setSeckillGoods] = useState([])
    const [supplier,setSupplier] = useState({
      id: 0,
      title: ""
    })
    const [saleNum,setSaleNum] = useState(0)
    const [moreGoodsList,setMoreGoodsList] = useState([])
    const [reviewResult,setReviewResult] = useState({
      list: [],
      total_number: 0
    })
    const [isCheckouting,setIsCheckouting] = useState(false) 
    const [commentResult,setCommentResult] = useState({
      list: [{
        avatar: "https://tse4-mm.cn.bing.net/th/id/OIP-C._8Kty4btP3aJuyTfZTaR0wHaHk?w=202&h=206&c=7&r=0&o=5&dpr=2&pid=1.7",
        nickname: "Stocker",
        create_time: 1726112562,
        message: "If the code can contain frontend and backend ,well be better",
        child:[
          {
            avatar: "https://tse1-mm.cn.bing.net/th/id/OIP-C.mEma0ZcipymPAHIYoIuFiAHaJa?w=162&h=206&c=7&r=0&o=5&dpr=2&pid=1.7",
            nickname: "Kevin Ashley",
            create_time: 1726110562,
            message: "Yes, the template can be developed further"
          }
        ]
      },

    ],
      total_number: 2
    })

    useEffect(() => { init() },[])

    useEffectOnce(() => {
        
        // load()
    })

    function init() {
      const type = queryParameters.get("type")
      console.log('72 here ',type)
      if( type ) {
        setType(type)
      }
      load(type)
    }
    
    function load(type) {
        console.log('79 load type is',type)
        if(type === GOOD_TYPE_SECKILL ){
            loadSeckillInfo()
        } else {
            loadGoodInfo()
        }
    }

    async function loadGoodInfo() {
        const param = {
            sn:id 
        }
        const response = await goodsApi.info(param)
        if(response && response?.code === 0) {
            const info = response.result.info;
            setValue(info)
        }
    }

    async function loadSeckillInfo() {
        console.log('load seckill info called...')
        const payload = {
          seckill_id: id 
        }
        const response = await seckillApi.info(payload)
        console.log('seckill response is', response)
        if(response && response.code === 0) {
          const info = response.result.info;
          setValue(info)
        }
    }

    function setValue( info ) {
          setTitle(info?.title)
          setDescription(info?.description)
          setCategoryLabel(info?.category_label)
          setImg(info?.img)
          setImages(info?.images)
          setFeatures(info?.features)
          setTags(info?.tags)
          setPreviewUrl(info?.url?.preview_url)
          setSupplier(info?.supplier)
          setSaleNum(info?.sale_num)
          setSkus(info?.skus)

          loadReviews(info?.id)
          loadMoreGoods(info?.supplier?.id)

          setSeckillGoods(info?.seckill_goods)
    }

    async function loadMoreGoods(supplierId)
    {
      const response = await goodsApi.list({ supplier_id: supplierId })

      if(response && response.code === 0) {
        setMoreGoodsList(response.result.list)
      }
    }

    async function loadReviews(goods_id){
      const response = await goodsReviewApi.list({ goods_id })
      if(response && response.code === 0) {
        setReviewResult(response.result)
      }
    }

    async function checkout(goodsSkuId,goodsId, seckillId) {
        
      const token = localStorage.getItem(TOKEN)
      const href = window.location.href;
      const pathname = window.location.pathname
      const redirectUrl = href.substring(href.indexOf(pathname))
   

      if( !token || token == null ) {
          // login account 
          localStorage.setItem(REDDIRECT_URL,redirectUrl)
          navigate("/login")
      } else {
          console.log('create order ...')
          createOrder(goodsSkuId,goodsId, seckillId)
      }
  }

  async function createOrder(goodsSkuId,goodsId, seckillId) {
      try {
        setIsCheckouting(true)
        if(type === GOOD_TYPE_NORMAL) {
          const param = {
            goods_sku_id: goodsSkuId,
            quantity: 1
          }
          const response = await buyApi.createOrderByGoodsSkuId(param)
          console.log('155 create order by goods sku id response',response)
          if(response && response.code === 0) {
            const result = response.result;
            const pay_sn = result.pay_sn;
            const order_id = result.order_id;
  
            // 跳转到支付页面
            navigate(`/payment/${pay_sn}`)
          }
        } 
        else if(type === GOOD_TYPE_SECKILL) {
            console.log('193 seckill create order ',seckillId)
            const payload = {
                goods_sku_id: goodsSkuId,
                seckill_id: seckillId,
                goods_id:goodsId,
                goods_number: 1,
            }
  
             try {
                const response = await seckillBuyApi.createOrder(payload);
                console.log('seckill buy create order response',response)
                if(response && response.code === 0) {
                  const result = response.result;
                  const pay_sn = result.pay_sn;
                  const order_id = result.order_id;
        
                  // 跳转到支付页面
                  navigate(`/payment/${pay_sn}`)
                }
             } catch (error) {
              
             }
        }
      } catch (error) {
        
      }
      finally {
        setIsCheckouting(false)
      }
  }


  return (

    <>
      {/* Preloader */}
      <BaseLayout>
           {/* BreadcrumbTwo */}
          <BreadcrumbTwo 
            title={title}
            categoryLabel={categoryLabel}
            supplier={supplier}
            saleNum={saleNum}
            reviewRate={5.0}
            reviewTotal={reviewResult.total_number}
            commentTotal={commentResult.total_number}
          />

          {/* ProductDetails */}
          <ProductDetails
            type={type}
            reviewList={reviewResult.list}
            previewUrl={previewUrl}
            img={img}
            images={images}
            description={description}
            features={features}
            tags={tags}
            supplierId={supplier?.id}
            moreGoodsList={moreGoodsList}
            commentTotal={commentResult.total_number}
            commentList={commentResult.list}
            skus={skus}
            seckillGoods={seckillGoods}
            checkout={checkout}
            isCheckouting={isCheckouting}
          />

          {/* BrandSectionOne */}
          <BrandSectionOne />
      </BaseLayout>

    </>
  );
};

export default ProductDetailsPage;
