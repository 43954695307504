import { Link } from "react-router-dom";
import { PhotoProvider, PhotoView } from 'react-photo-view';
import ProductReviewCard from "./product-detail/reviewCard";
import CommentsList from "./comments/list";
import { useEffect, useState } from "react";
import { ReactSVG } from 'react-svg'
import { GOOD_TYPE_NORMAL } from "../constants";
const ProductDetails = ({
    type,
    reviewList,
    img,
    images,
    previewUrl,
    description,
    features,
    tags,
    supplierId,
    moreGoodsList,
    commentTotal,
    commentList,
    skus,
    checkout,
    seckillGoods,
    isCheckouting
}) => {
    const [currentSku,setCurrentSku] = useState(skus[skus.length - 1])
    
    useEffect(() => {
        setCurrentSku(skus[skus.length - 1])
    })

    function handleCheckout(){
        
        if(type == GOOD_TYPE_NORMAL) {
            checkout(currentSku.id, null,null)
        } else {
            const seckillGood = seckillGoods[0]
            checkout(seckillGood.goods_sku_id, seckillGood.goods_id, seckillGood.seckill_id)
        }
    }
    
    return (
        <div className="product-details mt-32 padding-b-120">
            <div className="container container-two">
                <div className="row gy-4">
                    <div className="col-lg-8">
                        <div className="tab-content" id="pills-tabContent">
                            <div
                                className="tab-pane fade show active"
                                id="pills-product-details"
                                role="tabpanel"
                                aria-labelledby="pills-product-details-tab"
                                tabIndex={0}
                            >
                                {/* Product Details Content Start */}
                                <div className="product-details">
                                    <div className="product-details__thumb">
                                        <img src={img} alt="" />
                                    </div>
                                    <div className="product-details__buttons flx-align justify-content-center gap-3">
                                        <Link
                                            to={previewUrl}
                                            className="btn btn-main d-inline-flex align-items-center gap-2 pill px-sm-5 justify-content-center"
                                        >
                                            Live Preview
                                            <img src="../assets/images/icons/eye-outline.svg" alt="" />
                                        </Link>
                                        <PhotoProvider>
                                            {
                                                images && images.length > 0 && images.slice(1).map((item,index) => {
                                                   return <PhotoView key={`image-${index}`} src={item}>
                                                        {/* <Link
                                                            to="#"
                                                            className="screenshot-btn btn btn-white pill px-sm-5"
                                                        >
                                                            
                                                            Screenshot
                                                        </Link> */}
                                                        <img src={item} style={{ objectFit: 'cover' }} alt="" />
                                                    </PhotoView>
                                                })
                                            }

                                        </PhotoProvider>
                                        
                                    </div>
                                    <p className="product-details__desc">
                                        {description}
                                    </p>
                                    <div className="product-details__item">
                                        <h5 className="product-details__title mb-3">
                                            Template Features
                                        </h5>
                                        <ul className="product-list">
                                            {
                                                features && features.length > 0 && features.map((item,index) => {
                                                    return (
                                                        <li key={`feature-${index}`} className="product-list__item">
                                                            {item?.title}
                                                        </li>
                                                    )
                                                } )
                                            }
                                            
                                        </ul>
                                    </div>
                                    <div className="product-details__item">
                                        <h5 className="product-details__title mb-3">Tags</h5>
                                        <ul className="product-list">
                                            {
                                                tags && tags.length > 0 && tags.map((item,index) => {
                                                    return <li key={`tag-${index}`} className="product-list__item">{item.title}</li>
                                                })
                                            }
                                            
                                        </ul>
                                    </div>
                             
                                    <div className="more-item">
                                        <div className="flx-between mb-4">
                                            <h5 className="more-item__title">More Items</h5>
                                            <Link
                                                to={`/profile/${supplierId}`}
                                                className="text-heading fw-500 hover-text-decoration-underline"
                                            >
                                                View Author Profile
                                            </Link>
                                        </div>

                                        <div className="more-item__content flx-align">
                                            {
                                                moreGoodsList && moreGoodsList.length > 0 && moreGoodsList.map((item,index) => {
                                                   return <div className="more-item__item" key={`good-${index}`}>
                                                        <Link
                                                            target="_blank"
                                                            to={`/product-details/${item?.sn}`}
                                                            className="link w-100 h-100 d-block"
                                                        >
                                                            <img src={item.img} alt="" />
                                                        </Link>
                                                    </div>
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                                {/* Product Details Content End */}
                            </div>
                            <div
                                className="tab-pane fade"
                                id="pills-rating"
                                role="tabpanel"
                                aria-labelledby="pills-rating-tab"
                                tabIndex={0}
                            >
                                <div className="product-review-wrapper">
                                    {
                                        reviewList && reviewList.length > 0 && reviewList.map((item,index) => {
                                            return (
                                                <ProductReviewCard 
                                                    key={`review-card-${index}`}
                                                    review={item}
                                                />
                                            )
                                        })
                                    }
                                    
                                    
                                </div>
                            </div>
                            <div
                                className="tab-pane fade"
                                id="pills-comments"
                                role="tabpanel"
                                aria-labelledby="pills-comments-tab"
                                tabIndex={0}
                            >
                                {/* Comment Start */}
                                <div className="comment mt-64 mb-64">
                                    <h5 className="mb-32">{commentTotal} Comments</h5>
                                    <CommentsList list={commentList} />
                                </div>
                                {/* Comment End */}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        {/* ======================= Product Sidebar Start ========================= */}
                        <div className="product-sidebar section-bg">
                            <div className="product-sidebar__top position-relative flx-between gap-1">
                                <button
                                    type="button"
                                    className="btn-has-dropdown font-heading font-18"
                                >
                                    Extended License
                                </button>
                                <div className="license-dropdown">
                                    <div className="license-dropdown__item mb-3">
                                        <h6 className="license-dropdown__title font-body mb-1 font-16">
                                            Regular License
                                        </h6>
                                        <p className="license-dropdown__desc font-13">
                                            Use, by you or one client, in a solitary finished result which
                                            end clients are not charged for. The complete cost
                                            incorporates the thing cost and a purchaser expense..
                                        </p>
                                    </div>
                                    <div className="license-dropdown__item">
                                        <h6 className="license-dropdown__title font-body mb-1 font-16">
                                            Extended License
                                        </h6>
                                        <p className="license-dropdown__desc font-13">
                                            Use, by you or one client, in a solitary final result which
                                            end clients can be charged for. The all out cost incorporates
                                            the thing cost and a purchaser expense.
                                        </p>
                                    </div>
                                    <div className="mt-3 pt-2 border-top text-center ">
                                        <Link
                                            to="#"
                                            className="link hover-text-decoration-underline font-14 text-main fw-500"
                                        >
                                            View License Details
                                        </Link>
                                    </div>
                                </div>
                                <h6 className="product-sidebar__title">${ type == GOOD_TYPE_NORMAL ? currentSku?.price : seckillGoods && seckillGoods[0]?.seckill_price}</h6>
                            </div>
                            <ul className="sidebar-list">
                                <li className="sidebar-list__item flx-align gap-2 font-14 fw-300 mb-2">
                                    <span className="icon">
                                        <img src="assets/images/icons/check-cirlce.svg" alt="" />
                                    </span>
                                    <span className="text">Quality verified</span>
                                </li>
                                <li className="sidebar-list__item flx-align gap-2 font-14 fw-300 mb-2">
                                    <span className="icon">
                                        <img src="assets/images/icons/check-cirlce.svg" alt="" />
                                    </span>
                                    <span className="text">Use for a single project</span>
                                </li>
                                <li className="sidebar-list__item flx-align gap-2 font-14 fw-300">
                                    <span className="icon">
                                        <img src="assets/images/icons/check-cirlce.svg" alt="" />
                                    </span>
                                    <span className="text">Non-paying users only</span>
                                </li>
                            </ul>
                            <div className="flx-between mt-3">
                                <div className="common-check mb-0">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        name="checkbox"
                                        id="extended"
                                    />
                                    <label
                                        className="form-check-label mb-0 fw-300 text-body"
                                        htmlFor="extended"
                                    >
                                        Extended support 12 month
                                    </label>
                                </div>
                                <div className="flx-align gap-2">
                                    <span className="product-item__prevPrice text-decoration-line-through">
                                        $12
                                    </span>
                                    <h6 className="product-item__price mb-0 font-14 fw-500">$0</h6>
                                </div>
                            </div>
                            <button
                                type="button"
                                className="btn btn-main d-flex w-100 justify-content-center align-items-center gap-2 pill px-sm-5 mt-32"
                                onClick={() => handleCheckout()}
                                disabled={isCheckouting}
                           >
                               
                                {/* <img src={isCheckouting ? '../assets/images/icons/loading.svg' : '../assets/images/icons/add-to-cart.svg'} alt="" /> */}
                                <img src={"../assets/images/icons/add-to-cart.svg"} alt="loading" />
                             
                                { isCheckouting ? "Checkouting.." : "Checkout Now"}
                            </button>
                            {/* Author Details Start*/}
                            <div className="author-details">
                                <div className="d-flex align-items-center gap-2">
                                    <div className="author-details__thumb flex-shrink-0">
                                        <img src="../assets/images/thumbs/author-details-img.png" alt="" />
                                    </div>
                                    <div className="author-details__content">
                                        <h6 className="author-details__name font-18 mb-2">
                                            <Link to="/profile" className="link hover-text-main">
                                                Oviousdev
                                            </Link>
                                        </h6>
                                        <span className="d-flex align-items-center gap-1">
                                            <span className="star-rating">
                                                <span className="star-rating__item font-11">
                                                    <i className="fas fa-star" />
                                                </span>
                                                <span className="star-rating__item font-11">
                                                    <i className="fas fa-star" />
                                                </span>
                                                <span className="star-rating__item font-11">
                                                    <i className="fas fa-star" />
                                                </span>
                                                <span className="star-rating__item font-11">
                                                    <i className="fas fa-star" />
                                                </span>
                                                <span className="star-rating__item font-11">
                                                    <i className="fas fa-star" />
                                                </span>
                                            </span>
                                            <span className="star-rating__text text-body"> 5.0</span>
                                        </span>
                                    </div>
                                </div>
                                <ul className="badge-list flx-align gap-2 mt-3">
                                    <li
                                        className="badge-list__item"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        data-bs-title="Badge Info"
                                    >
                                        <img src="../assets/images/thumbs/badge1.png" alt="" />
                                    </li>
                                    <li
                                        className="badge-list__item"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        data-bs-title="Badge Info"
                                    >
                                        <img src="../assets/images/thumbs/badge2.png" alt="" />
                                    </li>
                                    <li
                                        className="badge-list__item"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        data-bs-title="Badge Info"
                                    >
                                        <img src="../assets/images/thumbs/badge3.png" alt="" />
                                    </li>
                                    <li
                                        className="badge-list__item"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        data-bs-title="Badge Info"
                                    >
                                        <img src="../assets/images/thumbs/badge4.png" alt="" />
                                    </li>
                                    <li
                                        className="badge-list__item"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        data-bs-title="Badge Info"
                                    >
                                        <img src="../assets/images/thumbs/badge5.png" alt="" />
                                    </li>
                                    <li
                                        className="badge-list__item"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        data-bs-title="Badge Info"
                                    >
                                        <img src="../assets/images/thumbs/badge6.png" alt="" />
                                    </li>
                                    <li
                                        className="badge-list__item"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        data-bs-title="Badge Info"
                                    >
                                        <img src="../assets/images/thumbs/badge7.png" alt="" />
                                    </li>
                                    <li
                                        className="badge-list__item"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        data-bs-title="Badge Info"
                                    >
                                        <img src="../assets/images/thumbs/badge8.png" alt="" />
                                    </li>
                                </ul>
                                <Link
                                    to="/profile"
                                    className="btn btn-outline-light w-100 pill mt-32"
                                >
                                    View Portfolio
                                </Link>
                            </div>
                            {/* Author Details End */}
                            {/* Meta Attribute List Start */}
                            <ul className="meta-attribute">
                                <li className="meta-attribute__item">
                                    <span className="name">Last Update</span>
                                    <span className="details">Feb 21, 2024</span>
                                </li>
                                <li className="meta-attribute__item">
                                    <span className="name">Published</span>
                                    <span className="details">Feb 15, 2024</span>
                                </li>
                                <li className="meta-attribute__item">
                                    <span className="name">Category</span>
                                    <span className="details">Themes</span>
                                </li>
                                <li className="meta-attribute__item">
                                    <span className="name">Widget Ready</span>
                                    <span className="details">Yes</span>
                                </li>
                                <li className="meta-attribute__item">
                                    <span className="name">High Resolution</span>
                                    <span className="details">Yes</span>
                                </li>
                                <li className="meta-attribute__item">
                                    <span className="name">Copatible with</span>
                                    <span className="details">
                                        <Link to="#" className="hover-text-decoration-underline">
                                            Contact Form 7,
                                        </Link>
                                        <Link to="#" className="hover-text-decoration-underline">
                                            {" "}
                                            Calendar,
                                        </Link>
                                        <Link to="#" className="hover-text-decoration-underline">
                                            {" "}
                                            Elementor,
                                        </Link>
                                        <Link to="#" className="hover-text-decoration-underline">
                                            {" "}
                                            Elementor Pro,
                                        </Link>
                                        <Link to="#" className="hover-text-decoration-underline">
                                            {" "}
                                            WooCommerce 8.x.x
                                        </Link>
                                    </span>
                                </li>
                                <li className="meta-attribute__item">
                                    <span className="name">File size</span>
                                    <span className="details">85 MB</span>
                                </li>
                                <li className="meta-attribute__item">
                                    <span className="name">Framework</span>
                                    <span className="details">Underscores</span>
                                </li>
                                <li className="meta-attribute__item">
                                    <span className="name">Software Version</span>
                                    <span className="details">
                                        <Link to="#" className="hover-text-decoration-underline">
                                            WordPress 6.3.x,
                                        </Link>
                                        <Link to="#" className="hover-text-decoration-underline">
                                            WordPress 6.2.x,
                                        </Link>
                                        <Link to="#" className="hover-text-decoration-underline">
                                            WordPress 6.1.x,
                                        </Link>
                                        <Link to="#" className="hover-text-decoration-underline">
                                            WordPress 6.0.x,
                                        </Link>
                                        <Link to="#" className="hover-text-decoration-underline">
                                            WordPress 5.9.x,
                                        </Link>
                                    </span>
                                </li>
                                <li className="meta-attribute__item">
                                    <span className="name">Marketplace Files Included</span>
                                    <span className="details">
                                        <Link to="#" className="hover-text-decoration-underline">
                                            PHP Files,
                                        </Link>
                                        <Link to="#" className="hover-text-decoration-underline">
                                            CSS Files,
                                        </Link>
                                        <Link to="#" className="hover-text-decoration-underline">
                                            SCSS Files,
                                        </Link>
                                        <Link to="#" className="hover-text-decoration-underline">
                                            JS Files,
                                        </Link>
                                    </span>
                                </li>
                                <li className="meta-attribute__item">
                                    <span className="name">Layout</span>
                                    <span className="details">Responsive</span>
                                </li>
                                <li className="meta-attribute__item">
                                    <span className="name">Tags</span>
                                    <span className="details">
                                        <Link to="#" className="hover-text-decoration-underline">
                                            theme,
                                        </Link>
                                        <Link to="#" className="hover-text-decoration-underline">
                                            web design,
                                        </Link>
                                        <Link to="#" className="hover-text-decoration-underline">
                                            minimal design,
                                        </Link>
                                        <Link to="#" className="hover-text-decoration-underline">
                                            trendy,
                                        </Link>
                                        <Link to="#" className="hover-text-decoration-underline">
                                            responsive,
                                        </Link>
                                        <Link to="#" className="hover-text-decoration-underline">
                                            wordpress,
                                        </Link>
                                        <Link to="#" className="hover-text-decoration-underline">
                                            saas,
                                        </Link>
                                        <Link to="#" className="hover-text-decoration-underline">
                                            dashboard,
                                        </Link>
                                    </span>
                                </li>
                            </ul>
                            {/* Meta Attribute List End */}
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default ProductDetails;